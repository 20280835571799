import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
const Swal = () => import("sweetalert2");

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Forbidden from "../pages/Forbidden.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    redirect: "/dashboard/",
  },
  {
    path: "/forbidden",
    component: Forbidden,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/registrasi",
    component: () => import("../pages/Registrasi.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("../pages/users/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/users/Edit.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/password/:id",
    component: () => import("../pages/users/Password.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/users/Form.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },

  {
    path: "/quick-count",
    component: () => import("../pages/quick-count/List.vue"),
    meta: {
      requiredLevel: ["admin", "kordes"],
    },
  },
  {
    path: "/quick-count/create",
    component: () => import("../pages/quick-count/Form.vue"),
    meta: {
      requiredLevel: ["admin", "kordes", "korcam", "korwil"],
    },
  },
  {
    path: "/quick-count/update/:id",
    component: () => import("../pages/quick-count/Update.vue"),
    meta: {
      requiredLevel: ["admin", "kordes", "korcam", "korwil"],
    },
  },
  {
    path: "/data-dpt",
    component: () => import("../pages/dpt/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/data-pendukung",
    component: () => import("../pages/pendukung/List.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes", "relawan"],
    },
  },
  {
    path: "/data-pendukung/manual/",
    component: () => import("../pages/pendukung/Manual.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes", "relawan"],
    },
  },
  {
    path: "/data-pendukung/detail/:id",
    component: () => import("../pages/pendukung/Detail.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes", "relawan"],
    },
  },
  {
    path: "/data-pendukung/dpt/:id",
    component: () => import("../pages/pendukung/Form.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes", "relawan"],
    },
  },
  {
    path: "/data-pendukung/update/:id",
    component: () => import("../pages/pendukung/Update.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes", "relawan"],
    },
  },

  {
    path: "/data-saksi",
    component: () => import("../pages/saksi/List.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },
  {
    path: "/data-saksi/manual/",
    component: () => import("../pages/saksi/Manual.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },
  {
    path: "/data-saksi/set/:id",
    component: () => import("../pages/saksi/Form.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },

  {
    path: "/data-relawan",
    component: () => import("../pages/relawan/List.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },
  {
    path: "/data-relawan/set/:id",
    component: () => import("../pages/relawan/Form.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },
  {
    path: "/master/dapil/",
    component: () => import("../pages/master/dapil/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/dapil/detail/:id",
    component: () => import("../pages/master/dapil/Detail.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/target/",
    component: () => import("../pages/master/target/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/tim/",
    component: () => import("../pages/master/tim/List.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam", "kordes"],
    },
  },
  {
    path: "/rekap/pendukung/",
    component: () => import("../pages/rekap/pendukung/List.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam"],
    },
  },
  {
    path: "/rekap/pendukung/kabupaten/:id",
    component: () => import("../pages/rekap/pendukung/Kabupaten.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam"],
    },
  },
  {
    path: "/rekap/pendukung/kecamatan/:id",
    component: () => import("../pages/rekap/pendukung/Kecamatan.vue"),
    meta: {
      requiredLevel: ["admin", "korwil", "korcam"],
    },
  },

  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
  {
    path: "/profil/:page",
    component: () => import("../pages/profil/Profil.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const sipData = localStorage.getItem("sipData");
  if (sipData) {
    const user = JSON.parse(sipData);
    const token = user.token;

    // const formData = new FormData();
    // formData.append("token", token);
    axios
      // .post("/api/auth/status/", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // })
      .get("/v1/auth/status/", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("sipData");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
