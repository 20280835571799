<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      :class="{
        'fa-spin': this.isLoading,
      }"
      @click="fetchData()"
    >
      <i class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-red">
          <div class="stats-icon"><i class="fa fa-calendar-check"></i></div>
          <div class="stats-info">
            <h4>DATA TERINPUT HARI INI</h4>
            <p>{{ dataTerinput }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-orange">
          <div class="stats-icon"><i class="fa fa-id-badge"></i></div>
          <div class="stats-info">
            <h4>JUMLAH RELAWAN</h4>
            <p>{{ jumlahRelawan }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-grey-darker">
          <div class="stats-icon"><i class="fa fa-users"></i></div>
          <div class="stats-info">
            <h4>JUMLAH TARGET</h4>
            <p>{{ jumlahTarget }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-black-lighter">
          <div class="stats-icon"><i class="fa fa-handshake"></i></div>
          <div class="stats-info">
            <h4>JUMLAH PENDUKUNG</h4>
            <p>{{ jumlahPendukung }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-12 mb-3">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barQuick"></div>
      </div>
      <div class="col-md-12 mb-3">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barQuickKecamatan"></div>
      </div> -->
      <div class="col-md-12">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barDaily"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";
import highContrastLight from "highcharts/themes/high-contrast-light";
import highContrastData from "highcharts/modules/data";
import moment from "moment";

drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);
highContrastLight(Highcharts);
highContrastData(Highcharts);

export default {
  mixins: [sipData],
  name: "dashboard",
  data() {
    return {
      isLoading: false,
      dataTerinput: 0,
      jumlahRelawan: 0,
      jumlahTarget: 0,
      jumlahPendukung: 0,
      terinputHarian: [],
      quickCount: [],
      quick_kecamatan: [],
      quick_kelurahan: [],
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.dataTerinput = response.data.terinput;
          this.jumlahRelawan = response.data.relawan;
          this.jumlahTarget = response.data.target;
          this.jumlahPendukung = response.data.pendukung;
          this.terinputHarian = response.data.tren_terinput;
          this.quickCount = response.data.quick_qount;
          this.quick_kecamatan = response.data.kecamatan;
          this.quick_kelurahan = response.data.kelurahan;
          this.renderBarDaily();
          this.renderQuick();
          this.renderQuickKecamatan();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },

    renderQuickKecamatan() {
      var kecamatanData = this.quick_kecamatan;
      var kelurahanData = this.quick_kelurahan;

      var processedData = [];

      kecamatanData.forEach(function (kecamatan) {
        var idPrefix = kecamatan.id_kec + "-";

        processedData.push({
          id: idPrefix + "A",
          name: "Sutrianto, S.H",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg01)];
            }),
        });
        processedData.push({
          id: idPrefix + "B",
          name: "Ilham J.Lamahuseng",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg02)];
            }),
        });
        processedData.push({
          id: idPrefix + "C",
          name: "Moh. Arief Nugroho, S.Kep, Ns.",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg03)];
            }),
        });
        processedData.push({
          id: idPrefix + "D",
          name: "Fatma.M.Amin, S.H",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg04)];
            }),
        });
        processedData.push({
          id: idPrefix + "E",
          name: "Safriyanti Ridwan.Yt",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg05)];
            }),
        });
        processedData.push({
          id: idPrefix + "F",
          name: "Andi Muchtar, S.T",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.ag_caleg06)];
            }),
        });
      });

      Highcharts.chart(this.$refs.barQuickKecamatan, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Rekapitulasi Suara",
        },
        subtitle: {
          text: "Klik Nama Kecamatan Untuk Melihat Detail/Desa",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Sutrianto, S.H",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg01),
              drilldown: data.id_kec + "-A",
            })),
            color: "#04744D",
          },
          {
            name: "Ilham J.Lamahuseng",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg02),
              drilldown: data.id_kec + "-B",
            })),
            color: "#F4E606",
          },
          {
            name: "Fatma.M.Amin, S.H",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg04),
              drilldown: data.id_kec + "-D",
            })),
            color: "#253682",
          },
          {
            name: "Moh. Arief Nugroho, S.Kep, Ns.",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg03),
              drilldown: data.id_kec + "-C",
            })),
            color: "#FF5B57",
          },
          {
            name: "Safriyanti Ridwan.Yt",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg05),
              drilldown: data.id_kec + "-E",
            })),
            color: "#87BFE4",
          },
          {
            name: "Andi Muchtar, S.T",
            data: this.quick_kecamatan.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.ag_caleg06),
              drilldown: data.id_kec + "-F",
            })),
            color: "#4C0684",
          },
        ],
        drilldown: { allowPointDrilldown: true, series: processedData },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderQuick() {
      Highcharts.chart(this.$refs.barQuick, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Perolehan Suara",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          categories: [
            "Sutrianto, S.H",
            "Ilham J.Lamahuseng",
            "Fatma.M.Amin, S.H",
            "Moh. Arief Nugroho, S.Kep, Ns.",
            "Safriyanti Ridwan.Yt",
            "Andi Muchtar, S.T",
          ],
          crosshair: true,
          accessibility: {
            description: "Countries",
          },
        },
        yAxis: {
          title: {
            text: "Jumlah Suara",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Suara",
            data: [
              {
                name: "Sutrianto, S.H",
                y: parseInt(this.quickCount.ag_caleg01),
                color: "#04744D",
              },
              {
                name: "Ilham J.Lamahuseng",
                y: parseInt(this.quickCount.ag_caleg02),
                color: "#F4E606",
              },
              {
                name: "Fatma.M.Amin, S.H",
                y: parseInt(this.quickCount.ag_caleg04),
                color: "#253682",
              },
              {
                name: "Moh. Arief Nugroho, S.Kep, Ns.",
                y: parseInt(this.quickCount.ag_caleg03),
                color: "#FF5B57",
              },
              {
                name: "Safriyanti Ridwan.Yt",
                y: parseInt(this.quickCount.ag_caleg05),
                color: "#87BFE4",
              },
              {
                name: "Andi Muchtar, S.T",
                y: parseInt(this.quickCount.ag_caleg06),
                color: "#4C0684",
              },
            ],
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarDaily() {
      Highcharts.chart(this.$refs.barDaily, {
        chart: {
          type: "spline",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Timeline Perolehan Dukungan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Data Terinput",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Data Terinput",
            data: this.terinputHarian.map((data) => ({
              name: this.dateShortFormat(data.tanggal),
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },

    dateShortFormat(dateTime) {
      return moment(dateTime).format("DD-MM-YY");
    },
  },
};
</script>